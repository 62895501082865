.products-table-container {
  margin-top: 1vh;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.products-table {
  color: #b38601 !important;
  font-weight: 600;
  font-size: .95vw;
  margin: auto;
  border-collapse: collapse;
}

.filterBar {
  color: #b38601 !important;
  font-weight: 600;
  width: 88vw;
  font-size: .95vw;
  margin: auto;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.products-table th {
  border-width: 5px 0px 5px 0px !important;
  vertical-align: middle;
  text-align: center !important;

  border: 3px solid rgba(230, 175, 10, 0.5);
  background-color: rgb(247, 246, 246);
  color: #b38601 !important;
  font-weight: 700;
  text-align: center !important;
  vertical-align: middle;
  position: sticky;
  top: 0;
  z-index: 999;
  cursor: default;
  height: 12vh;
}

.products-table td {
  border-width: 0px 0px 5px 0px !important;
  vertical-align: middle;
  text-align: center !important;
  border-bottom-color: rgba(230,175,10,0.1);
}

.products-table tbody tr {
  /* border-bottom: 3px solid rgba(230, 175, 10, 0.2); */
  padding-bottom: 3vh;
}

.products-table .bg {
  background-color: rgba(0,200,0,0.05);
}

.products-table tbody tr:hover {
  background-color: rgba(230, 175, 10, 0.03);
}

.data-row {
  /* border-color: #E6AF0A; */
  /* border-width: 5px !important; */
  border: 2px solid rgba(230, 175, 10, 0.2);
  vertical-align: middle;
  font-weight: 400;
  text-align: center !important;
}

.products-table td img:hover {
  transform: scale(1.5);
  box-shadow: 1px 1px 15px 1px gray;
  background-color: white;
  cursor: pointer;
  z-index: 99999;
}

/* .products-table th:nth-child(1) {
  text-align: left !important;
} */

.products-table td:nth-child(1) {
  text-align: left !important;
}

.hoverImageTable:hover {
  z-index: 9 !important;
}

.sourceImage:hover .overlay {
  opacity: 0;
}

.sourceImage {
  --warvis: 0;
}

.sourceImage:hover {
  --warvis: 1 !important;
}

.sortColumn {
  cursor: pointer !important;
  text-decoration: underline dotted;
  text-underline-offset: 4px;
}

.graphicon:not(.graphiconhover):hover {
  transform: scale(1) !important;
  box-shadow: 0px 0px 0px 0px rgb(160, 160, 160) !important;
}

#iframe-container {
  position: absolute;
  width: 320px;
  height: 220px;
  cursor: move;
  border: 2px solid #333;
  background-color: white;
}