.tableImage:hover {
  transform: scale(1.2);
  transition: transform 0.4s ease; /* Smooth transition on hover */
  box-shadow: 1px 1px 15px 1px gray;
  background-color: white;
  cursor: pointer;
}

.iconPopup {
  border-radius: 5px; /* Makes it rounded */
  transition: transform 0.3s ease; /* Smooth transition on hover */
  padding: 5px;
}
.iconPopup:hover {
  transform: scale(1.3);
  box-shadow: 1px 1px 15px 1px gray;
  cursor: pointer;
}